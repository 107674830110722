<template>
    <div class="modal-tbf active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal mb-0">
				    <div class="icon"><icon-download /></div>
				    <div class="title">Descarcă <span class="yellow">Proforma</span></div>
                    <div class="description" v-html="$t('register_success.description_op')"></div>
                </div>

                <div class="submit-form">
                    <button class="button-submit square" @click="downloadInvoice">
                        <div class="text">{{ $t('register_success.button-action-op') }}</div>
				        <div class="icon"><icon-download /></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import IconDownload from "../Icons/Download"

export default {
	data(){
		return {
            arrayTranslations: [
                'register_success.title',
                'register_success.description',
                'register_success.button-action'
            ]
		}
	},
    props:{
        user: Object
    },
    components: {
        IconDownload
    },
    mounted() {
        let syncPaymentData = {
            package_id: this.user.packages.selectedPackage.id
        }

        axios.post(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/get-proforma`, syncPaymentData).then(({data}) => {
        
        })
    },
	methods: {
        downloadInvoice() {
            let syncPaymentData = {
                package_id: this.user.packages.selectedPackage.id
            }
            
            axios.post(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/get-proforma`, syncPaymentData).then(({data}) => {
                window.open(data.url, '_blank');
            })
        }
	}
}
</script>

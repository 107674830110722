<template>
	<div class="container-modal form-modal" id="step1">
		<div class="container-holder" v-if="loaded">
			<div class="header-modal">
				<div class="icon"><icon-cart /></div>
				<div class="title" v-html="$t('register.title')"></div>
				<div class="description">{{ $t('register.description_step_1') }}</div>
			</div>

			<div class="form-section-register">
				<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.user.first_name.$error}">
					<div class="input-box">
						<div class="icon-right-new"><icon-people /></div>
						<div class="required-icon">*</div>
						<input type="text" :placeholder="$t('general.first_name')" class="input-text" v-model="user.first_name">
					</div>
				</div>
				
				<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.user.last_name.$error}">
					<div class="input-box">
						<div class="icon-right-new"><icon-people /></div>
						<div class="required-icon">*</div>
						<input type="text" :placeholder="$t('general.last_name')" class="input-text" v-model="user.last_name">
					</div>
				</div>

				<div class="input-login-group-tbf w-tbf-100 w-mob-100" v-bind:class="{has_error: $v.user.email.$error || mail_exists}">
					<div class="input-box">
						<div class="icon-right-new"><icon-email /></div>
						<div class="required-icon">*</div>
						<input type="email" :placeholder="$t('general.email-placeholder')" class="input-text" v-model="user.email">
					</div>
					<div class="error-msg" v-if="mail_exists">{{ $t('validator.email_already_exists') }}</div>
				</div>

				<div class="input-login-group-tbf w-tbf-100 w-mob-100" v-bind:class="{has_error: $v.user.password.$error}">
					<div class="input-box">
						<div class="icon-right-new"><icon-lock /></div>
						<div class="required-icon">*</div>
						<input type="password" :placeholder="$t('register.password-placeholder')" class="input-text" v-model="user.password">
					</div>
					<div class="error-msg pwd-error-msg" v-if="$v.user.password.$error && !$v.user.password.minLength">{{ $t('validator.password_min_length') }}</div>
				</div>
				
				<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.user.phone.$error, complete: user.phone != '' && !$v.user.phone.$error}">
					<div class="input-box">
						<div class="icon-right-new"><icon-phone /></div>
						<div class="required-icon">*</div>
						<input type="number" :placeholder="$t('register.phone-placeholder')" class="input-text phone" v-model="user.phone">
					</div>
					<div class="error-msg pwd-error-msg" v-if="$v.user.phone.$error && !$v.user.phone.phoneValid">{{ $t('validator.phone_invalid') }}</div>
				</div>

				<div class="input-login-group-tbf w-mob-100 w-tbf-100" v-bind:class="{has_error: $v.user.country.$error, complete: user.country != '' && !$v.user.country.$error}">
					<div class="input-box">
						<div class="icon-right-new"><icon-globe /></div>
						<div class="required-icon">*</div>
						<multiselect 
							class="select-tags-tbf"
							v-model="user.country"
							:options="optionsCountries"
							:allow-empty="true"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							:placeholder="$t('register.country-placeholder')"
							track-by="iso" 
							label="name"
							:maxHeight="250"
							openDirection="top"
							>
							<template class="no-result" slot="noResult">{{ $t('general.no_result') }}</template>
							<template slot="noOptions">{{ $t('general.empty_list') }}</template>
						</multiselect>
					</div>
				</div>
				<!-- <div class="input-login-group-tbf mb-0 w-tbf-100 checkboxes-group" v-if="user.country.name && user.country.name != 'Romania'"> -->
				<div class="input-login-group-tbf mb-0 w-tbf-100 checkboxes-group" v-if="false">
					<p class="notice-red">
						Această ofertă este exclusiv pentru societăți și persoane fizice din România. Dacă vrei să faci achiziția folosind date din altă țară, te rugăm să ne scrii un email la <a href="mailto:digital@tbf.ro">digital@tbf.ro</a> pentru a stabili ce tip de TVA ți se aplică.
					</p>
				</div>
				<!-- <div class="input-login-group-tbf mb-0 w-tbf-100 checkboxes-group" v-if="!user.country.name || user.country.name == 'Romania'"> -->
				<div class="input-login-group-tbf mb-0 w-tbf-100 checkboxes-group" v-if="true">
					<label class="checkbox-tbf" v-bind:class="{'error-msg': $v.user.terms_and_conditions.$error}">
						<span class="text-checkbox" href="https://tbf.ro/termeni-si-conditii-tbf-digital/" v-html="$t('register.terms', {terms_link: 'https://tbf.ro/termeni-si-conditii-tbf-digital/'})">
						</span>
						<input type="checkbox" value="1" v-model="user.terms_and_conditions">
						<span class="checkmark"></span>
					</label>
					
					<label class="checkbox-tbf">
						<span class="text-checkbox " v-html="$t('register.terms_notifications')">
						</span>
						<input type="checkbox" value="1" v-model="user.terms_notifications">
						<span class="checkmark"></span>
					</label>						
				</div>
			</div>

			<!-- <div class="submit-form" v-if="!user.country.name || user.country.name == 'Romania'"> -->
			<div class="submit-form" v-if="true">
				<button id="buttonNextStep" class="button-submit" @click="goNextStep">
					<div class="loader-spin" v-if="loadingSubmit"></div>
					<div class="text" v-else>{{$t('register.create_account')}}</div>
				</button>
			</div>
		</div>
		<div class="loader-step" v-else>
			<div class="loader-spin"></div>
		</div>
	</div>
</template>


<script>
	import IconClose from '../Icons/Close'
	import IconPeople from "../Icons/People"
	import IconUsers from "../Icons/Users"
	import IconEmail from '../Icons/Email'
	import IconLock from '../Icons/Lock'
	import IconBuilding from '../Icons/Building'
	import IconPhone from '../Icons/Phone'
	import IconGlobe from '../Icons/Globe'
	import IconCart from '../Icons/Cart'

	import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
	const isPhone = (value) => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value)
	export default {
		data(){
			return {
				mail_exists: false,
				user: {
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					password: '',
					terms_and_conditions: '',
					country: '',
					terms_notifications: ''
				},
				optionsCountries: [],
				loadingSubmit: false,
				loaded: false
			}
		},
		components: {
			IconClose,
			IconPeople,
			IconEmail,
			IconLock,
			IconBuilding,
			IconUsers,
			IconPhone,
			IconGlobe,
			IconCart
		},
		watch: {
			'user.first_name': function(val) {
				this.user.first_name = val.charAt(0).toUpperCase() + val.substring(1);
			},
			'user.last_name': function(val) {
				this.user.last_name = val.charAt(0).toUpperCase() + val.substring(1);
			}
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				country: {required},
				email: {required, email},
				phone: {
					phoneValid:isPhone
				},
				password: {required,minLength: minLength(6)},
				terms_and_conditions: { sameAs: val => val === true }
			}
		},
		async mounted() {
			await this.getCountries()
			this.user.has_company = true;
			this.user.referral_uuid = this.$route.query.referral ? this.$route.query.referral : false

			this.$cookies.set('firstRegistration', true, '1h')
		},
		methods: {
			async getCountries(){
				await axios.get('/countries')
				.then(({data}) => {
					for (var key of Object.keys(data.data)){
						this.optionsCountries.push({iso: key, name: data.data[key]})
					}

					this.user.country = this.optionsCountries.find(el => el.iso == 'RO');
				})
				.finally(() => {
					this.loaded = true
				})
			},
			goNextStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);

				btnSubmit.disabled = true
				this.loadingSubmit = true
				
				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmit.disabled = false
					this.loadingSubmit = false
				}else{
					this.submitRegister(btnSubmit)
				}
			},
			toLogin() {
				this.$router.push("/login")
			},
			submitRegister(btnSubmit){
				var formData = {
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					password: this.user.password,
					email: this.user.email,
					phone: this.user.phone,
					agree_with_terms: this.user.terms_and_conditions,
					country: this.user.country ? this.user.country.iso : 'RO'
				}

				if(this.$cookies.get('optionsRegisterUser').discount) {
					formData.reducere = this.$cookies.get('optionsRegisterUser').discount
				}
				
				var app = this
				this.$auth.register({
					data: formData,
					redirect: {name: 'register-packages'},
					staySignedIn: true,
					autoLogin: true,
					fetchUser: true
				})
				.then(() => {
					// btnSubmit.disabled = true
					// this.loadingSubmit = true
				}, (res) => {
					var statusCode = res.response.status
					let response = res.response.data

					if(res.response.data.hasOwnProperty('errors') && res.response.data.errors.hasOwnProperty('email')){
						this.mail_exists = res.response.data.errors.hasOwnProperty('email')

						if(res.response.data.errors.is_entrepreneur[0]){
							this.$root.$emit('open_modal', 'small_login', {email: app.user.email})
						}else{
							this.$root.$emit('open_modal', 'cant_create_account', { email: res.response.data.errors.entrepreneur_email[0]})
						}
					}

					btnSubmit.disabled = false
					this.loadingSubmit = false
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
						this.loadingSubmit = false
					}, 1500)
				})
			}
		}
	}
</script>
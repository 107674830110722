<template>
	<div class="active modal-register">
		<transition name="slide" mode="out-in">
			<register-step-1 v-if="step_active == 1 " @next_step="next_step"/>
			<register-step-2 v-if="step_active == 2 " :user="user" @next_step="next_step"/>
			<register-step-3 v-if="step_active == 3 " :user="user" @next_step="next_step"/>
			<register-step-4 v-if="step_active == 4 " :user="user" @next_step="next_step"/>
		</transition>

		<div class="bottom-icons">
			<div class="item">
				<div class="icon"><icon-lock /></div>
				<div class="text">
					<div class="primary">{{ $t('register.bottom_icons_1_title') }}</div>
					<div class="secondary">{{ $t('register.bottom_icons_1_desc') }}</div>
				</div>
			</div>
			<div class="item">
				<div class="icon"><icon-thumbs-up /></div>
				<div class="text">
					<div class="primary">{{ $t('register.bottom_icons_2_title') }}</div>
					<div class="secondary">{{ $t('register.bottom_icons_2_desc') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RegisterStep1 from './RegisterStep1'
import RegisterStep2 from './RegisterStep2'
import RegisterStep3 from './RegisterStep3'
import RegisterStep4 from './RegisterSuccessOp'

import IconLock from '../Icons/LockBold'
import IconThumbsUp from '../Icons/ThumbsUp'

export default {
	data(){
		return {
			step_active: 0,
			user: {
				coupon: null,
				newPrices: []
			}
		}
	},
	components: {
		RegisterStep1,
		RegisterStep2,
		RegisterStep3,
		RegisterStep4,
		IconLock,
		IconThumbsUp
	},
	watch: {
		$route (to, from){
			switch (this.$route.name) {
				case 'register':
					this.step_active = 1
					break;
				case 'register-packages':
					if(this.$auth.check()) {
						this.step_active = 2
					} else {
						this.$router.push({name: 'register'})
					}
					break;
			}
		}
	},
	mounted() {
		switch (this.$route.name) {
			case 'register':
				this.step_active = 1
				break;
			case 'register-packages':
				if(this.$auth.check()) {
					this.step_active = 2
				} else {
					this.$router.push({name: 'register'})
				}
				break;
		}

		if(!this.$cookies.get('optionsRegisterUser')){
			this.$router.push({name: 'pricing'})
		}
	},
	methods: {
		viewGame(){
			$('.bird-login').removeClass('active');
			setTimeout(() => {
				this.view_game = true;
				setTimeout(() => {
					game_start();
				},0)
			},300)
		},
		next_step(value){
			this.user = value.user ? value.user : this.user

			if(value.action == 'finish_redirect'){
				this.$router.push('/')
			}else{
				this.step_active = value.next_step ? value.next_step : this.step_active
			}
		}
	}
}
</script>
<template>
	<div class="container-modal form-modal large" >
		<div class="container-holder" v-if="loaded">
			<div class="info-content">
				<div class="header-modal">
					<div class="icon"><icon-cart /></div>
					<div class="title" v-html="$t('register.title_2')"></div>
				</div>
				
				<div class="container-content-modal" v-if="packageSelected">
					<div class="card-pack" :class="{'disabled': packageSelected.active_subscription && !packageSelected.active_subscription_details.grace_period }">
						<div class="pack-content">
							<div class="icon">
								<icon-check/>
							</div>
							<div class="pack-details">
								<div class="pack-name">
									{{ packageSelected.name }}
								</div>
								<div class="pack-type">
									{{ `${$t('register.subscription')}: ${ packageSelected.interval == 'month' ? $t('subscription.monthly') : $t('subscription.yearly') }` }}
								</div>
							</div>
							<!-- <div class="pack-price">
								<div class="price">
									<span class="old-price" v-if="packageSelected.old_price_in_euro != packageSelected.price_in_euro">{{ packageSelected.old_price_in_euro }}€</span>
									<span>{{ `${packageSelected.price_in_euro}€` }}</span>
									<span class="monthly">{{ selectedOptionsCookies.type == 'year' ? $t('subscription.yearly') : $t('subscription.monthly') }}</span> 
								</div>
								<div class="TVA">
									{{ $t('subscription.tva') }}
								</div>
							</div> -->
						</div>

						
						<div class="description" v-if="freePackages">
							<span v-if="packageSelected.active_subscription_details">
								{{ $t('subscription.can_not_change', {oldPlan: packageSelected.active_subscription_details.is_free ? 'Gratuit' : packageSelected.active_subscription_details.package_name, newPlan: packageSelected.name == 'Free' ? 'Gratuit' : packageSelected.name}) }}
							</span>
							<span v-else>
								{{ $t('subscription.can_not_change_simple', {newPlan: packageSelected.name == 'Free' ? 'Gratuit' : packageSelected.name}) }}
							</span>
						</div>

						<div class="description" v-else-if="packageSelected.active_subscription_details && !packageSelected.active_subscription_details.grace_period">
							<span v-if="packageSelected.active_subscription_details.package_id == packageSelected.id">
								{{ $t('subscription.has_same_plan', {oldPlan: packageSelected.active_subscription_details.is_free ? 'Gratuit' : packageSelected.active_subscription_details.package_name}) }}
							</span>
							<span v-else>
								{{ $t('subscription.plan_will_change', {oldPlan: packageSelected.active_subscription_details.is_free ? 'Gratuit' : packageSelected.active_subscription_details.package_name, newPlan: packageSelected.name == 'Free' ? 'Gratuit' : packageSelected.name}) }}
							</span>
						</div>
					</div>

					<div class="submit-footer" v-if="can_continue_checkout">
						<div class="total-price">
							<!-- <span class="total">{{ $t('subscription.total')}}</span> -->
							<span class="price">€{{ packageSelected.price_in_euro }}</span>
							<!-- <span class="tva">{{ bundleData.applications.interval == 'year' ? $t('subscription.yearly') : $t('subscription.monthly') }}{{ $t('subscription.tva') }}</span> -->
							<span class="tva">{{ packageSelected.interval == 'year' ? $t('subscription.year') : $t('subscription.month') }}{{ $t('subscription.tva') }}</span>
						</div>

						<div id="buttonNextStep" class="submit-form" @click="goNextStep">
							<div class="button-submit w-100">
								<div class="loader-spin" v-if="loadingSubmit"></div>
								<div v-else>{{ $t('subscription.next_step') }}</div>
							</div>
						</div>

						<div class="bottom-links">
							<router-link :to="{name: 'pricing'}" v-html="$t('register.choose_other_plan')"></router-link>
						</div>
					</div>
					<div class="submit-footer" v-else>
						<div class="submit-form" v-if="needChange">
							<button id="buttonChangePlan" class="button-submit w-100" @click="goChangePlan">
								<div class="loader-spin" v-if="loadingSubmit"></div>
								<div v-else>{{$tc('subscription.change_plans', 1)}}</div>
							</button>
						</div>
						<div v-else>
							{{ freePackages ? $t('subscription.can_not_change_to_free') : $tc('subscription.has_same_plan_summary', 1) }}
						</div>

						<div class="bottom-links">
							<router-link :to="{name: 'pricing'}" v-html="$t('register.choose_other_plan')"></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="loader-step" v-else>
			<div class="loader-spin"></div>
		</div>
	</div>
</template>


<script>
	import IconClose from '../Icons/Close'
	import IconPeople from '../Icons/People'
	import IconInvoice from '../Icons/Invoice'
	import IconSettings from '../Icons/Settings'
	import IconUsers from '../Icons/Users'
	import IconCheck from '../Icons/CircularCheck'
	import IconDiscount from '../Icons/Discount'
	import IconCart from '../Icons/Cart'

	import { required } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				finished: true,
				loaded: false,
				value_discount: 0,
				validCoupon: '',
				packageSelected: {},
				can_continue_checkout: false,
				needChange: false,
				freePackages: false,
				fromFreePackage: false,
				loadingSubmit: false,
				selectedOptionsCookies: {}
			}
		},
		props:{
			user: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconInvoice,
			IconSettings,
			IconUsers,
			IconCheck,
			IconDiscount,
			IconCart
		},
		validations: {
			selectedApps: {required: false}
		},
		async mounted() {
			this.selectedOptionsCookies = this.$cookies.get('optionsRegisterUser');

			await this.getPackages();
			this.validCoupon = this.user.coupon ? this.user.coupon : '';

		},
		methods: {
			async getPackages(){
				let paramsRequest = {
					token: this.selectedOptionsCookies.token,
					reducere: this.selectedOptionsCookies.discount,
				}

				// paramsRequest.from_external = true;
				await axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/packages/show`, {params: paramsRequest })
				.then(({data}) => {
					this.packageSelected = data.data;
					var oldPackage = this.packageSelected.active_subscription_details;

					if(this.packageSelected.active_subscription_details) {
						this.can_continue_checkout = !oldPackage.is_global_version || oldPackage.grace_period;
						this.fromFreePackages = oldPackage.is_free;
						this.needChange = oldPackage.package_id != this.packageSelected.id;
					} else {
						this.can_continue_checkout = true;
					}


					this.user.need_instance = oldPackage && oldPackage.is_global_version ? false : true;
					// Pe register ne lipseste asa
					// Sa stiu ce package id are omu inainte sa faca register pasul 2
					// deoarece aici verificam daca are pachet free ===> Nu il lasam sa faca upgrade
					// Daca avea acelasi pachet ii ziceam ca nu poate sa faca upgrade la nimica
					
					this.freePackages = this.packageSelected.price_in_euro == 0 ? true : false;

					// Daca avea acelasi pachet dar era pe Grace_period il lasam sa treaca mai departe
					// this.can_continue_checkout = this.bundleData.applications.find(el => el.active_subscription == false || (el.active_subscription && el.active_subscription_details.grace_period)) ? true : false

					// if(this.freePackages) {
					// 	this.needChange = this.bundleData.applications.find(el => !el.has_package) ? this.needChange : 0
					// }
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
					}, 0)
				})
			},
			checkCoupon(){
				var buttonName = 'btnCheckCoupon'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.errorCoupon = false
				this.value_discount = 0

				axios.post('sync-validate-coupon', {coupon: this.user.coupon})
				.then(({data}) => {
					if(data.data.find(el => el.response.is_valid)){
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('register.coupon-success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								data.data.map((el) => {
									if(el.response.is_valid){
										this.user.newPrices.push({ application_id: el.application_id, prices: el.response.values })
										this.validCoupon = this.user.coupon
									}
								})

								if(this.bundleData){
									this.bundleData.applications.map(application => {
										var apps_discount_prices = this.user.newPrices.find((el) => el.application_id == application.application_id)
										if(apps_discount_prices && apps_discount_prices.prices.find((el) => el.package_id == application.package_id)){
											this.value_discount += parseFloat(application.price_in_euro) - parseFloat(apps_discount_prices.prices.find((el) => el.package_id == application.package_id).price)
										}
									})
								}

								setTimeout(() => {
									btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
									btnSubmit.classList.remove('completed')
								}, 500)
							}, 300)
						}, 300)
					}else{
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('register.coupon-error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
								this.errorCoupon = true
								this.user.newPrices = []
								this.validCoupon = ''
							}, 1000)
						}, 300)
					}
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('register.coupon-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('register.coupon-apply-short')
							this.errorCoupon = true
							this.user.newPrices = []
							this.validCoupon = ''
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			goNextStep(){
				var buttonName = 'buttonNextStep'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				this.loadingSubmit = true;

				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				}else{

					this.user.packages = {
						selectedPackage: this.packageSelected,
						value_discount_voucher: this.value_discount,
						total_price_in_euro: this.packageSelected.price_in_euro - this.value_discount,
						total_price_in_euro_with_vat: this.packageSelected.price_in_euro_with_vat - (this.value_discount ? this.packageSelected.price_in_euro_with_vat : 0),
						total_price_in_ron_with_vat: this.packageSelected.price_in_ron_with_vat - (this.value_discount ? this.packageSelected.price_in_ron_with_vat : 0)
					}

					this.user.freePackages = this.freePackages;
					this.user.company_name = this.$auth.user().company_name ? this.$auth.user().company_name : '';

					this.$emit("next_step", {user: this.user, next_step: 3});
				}
			},
			goChangePlan(){
				var buttonName = 'buttonChangePlan'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true
				this.loadingSubmit = true;

				var formData = {};
				formData.package_token = this.packageSelected.token;

				axios.post(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/change-plan-account`, formData)
				.then(({data}) => {
					if(data.status == 'error'){
						btnSubmit.disabled = false
					}else{
						this.$router.push({name: 'register-success'})
					}
				})
				.catch(error => {
					this.onPayment = false
					btnSubmit.disabled = false
				})
			}
		}
	}
</script>

<style lang="scss">
	.checkout-template{
		display: flex;
		align-items: flex-start;
		@media (max-width: 780px) {
			flex-direction: column;
		}
		.form-section-register{
			flex: 1;
			padding-right: 30px;
			width: 100%;
			@media (max-width: 780px) {
				padding-right: 0;
				margin-left: 0;
				.input-login-group-tbf.w-tbf-100{
					padding: 0;
				}
			}
		}
		.summary-checkout{
			flex: 0 0 290px;
			max-width: 290px;
			@media (max-width: 780px) {
				max-width: initial;
				width: 100%;
				flex: 0 0 100%;
				margin-top: 30px;
			}
			.title{
				font-weight: 500;
				font-size: 22px;
				color: $primary;
			}
			.box-info{
				margin-top: 15px;
				margin-bottom: 15px;
				border-radius: 10px;
				padding: 15px;
				background: #FCFCFC;
				border: 1px solid #DCDEE1;
				font-size: 14px;
				line-height: 1.4;
				a{
					font-weight: 700;
					color: $primary;
				}
				.item-data{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 5px;
					.label{
						.soft{
							color: $primary;
							font-size: 14px;
							margin-bottom: 2px;
						}
						.plan{
							color: $placeholderColor;
							font-size: 12px;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.data{
				margin-top: 20px;
				.subtitle{
					font-weight: 500;
					font-size: 15px;
					margin-bottom: 10px;
					&.coupon{
						margin-top: 30px;
					}
				}
				.item-data{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 5px;
					&.package{
						margin-bottom: 10px;
					}
					.label{
						.soft{
							color: $primary;
							font-size: 14px;
							margin-bottom: 2px;
						}
						.plan{
							color: $placeholderColor;
							font-size: 12px;
						}
						.simple-text{
							color: $placeholderColor;
							font-size: 14px;
							line-height: 17px;
						}
						.total{
							color: $primary;
							font-size: 14px;
						}
					}
					.value{
						color: $primary;
						font-size: 14px;
					}
				}
				.coupon-data{
					margin-bottom: 30px;
					.input-box{
						border-radius: 10px;
						border: 1px solid #dcdee1;
						position: relative;
						display: flex;
						&:focus-within {
							box-shadow: inset 0 -1px 5px 0 rgba(255, 255, 255, 0.25);
							border: solid 1px rgb(223, 224, 226);
							background-color: rgb(246, 246, 247);
						}
						input{
							height: 38px;
							padding: 0 15px;
							width: 100%;
							border: 0;
							font-size: 12px;
							color: $primary;
							background: transparent;
							&::-webkit-input-placeholder {color: $placeholderColor;}
							&:-moz-placeholder           {color: $placeholderColor;}
							&::-moz-placeholder          {color: $placeholderColor;}
							&:-ms-input-placeholder      {color: $placeholderColor;}
							&:focus{
								outline: none;
							}
						}
						.btn-tbf{
							border-radius: 10px;
							width: 115px;
							min-width: 115px;
							margin-top: -1px;
							margin-bottom: -1px;
							margin-right: -1px;
							height: 42px;
						}
					}
				}
				.line-total{
					height: 1px;
					width: 100%;
					background: $borderColor;
					margin: 15px 0;
				}
			}
		}
	}

	.checkout-submit{
		align-items: flex-end;
		flex-direction: column;
		.error-msg{
			margin-bottom: 5px;
		}
		.btn-cancel{
			margin-top: 15px;
		}
	}
</style>